import Flights from '../../components/flights/Flights';

const Home = () => {
	return (
		<>
			<Flights />
		</>
	);
};

export default Home;
