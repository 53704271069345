// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_App__2\\+VHF {
  background-color: #0e131f;
  min-height: 100vh;
  width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,iBAAA;EACA,YAAA;AACD","sourcesContent":[".App {\n\tbackground-color: #0e131f;\n\tmin-height: 100vh;\n\twidth: 100vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `App_App__2+VHF`
};
export default ___CSS_LOADER_EXPORT___;
