// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nav_nav__AHi00 {
  padding: 10px 30px;
  display: flex;
  background-color: cyan;
  align-items: center;
}

.Nav_logo__SsnoW {
  background-color: white;
  padding: 5px;
  width: auto;
  height: 50px;
}

.Nav_nav_icon__mActY {
  transform: scale(1.4);
  margin: 10px 20px;
}

.Nav_logout__wOAFG {
  box-sizing: content-box;
  padding: 4px;
  border: 1px solid var(--secondary-text-color);
  border-radius: 4px;
  color: red;
}
.Nav_logout__wOAFG:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/nav/Nav.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACD;;AAEA;EACC,uBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AACD;;AAEA;EACC,qBAAA;EACA,iBAAA;AACD;;AAEA;EACC,uBAAA;EACA,YAAA;EACA,6CAAA;EACA,kBAAA;EACA,UAAA;AACD;AACC;EACC,eAAA;AACF","sourcesContent":[".nav {\n\tpadding: 10px 30px;\n\tdisplay: flex;\n\tbackground-color: cyan;\n\talign-items: center;\n}\n\n.logo {\n\tbackground-color: white;\n\tpadding: 5px;\n\twidth: auto;\n\theight: 50px;\n}\n\n.nav_icon {\n\ttransform: scale(1.4);\n\tmargin: 10px 20px;\n}\n\n.logout {\n\tbox-sizing: content-box;\n\tpadding: 4px;\n\tborder: 1px solid var(--secondary-text-color);\n\tborder-radius: 4px;\n\tcolor: red;\n\n\t&:hover {\n\t\tcursor: pointer;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": `Nav_nav__AHi00`,
	"logo": `Nav_logo__SsnoW`,
	"nav_icon": `Nav_nav_icon__mActY`,
	"logout": `Nav_logout__wOAFG`
};
export default ___CSS_LOADER_EXPORT___;
