// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loading_icon__GIgFG {
  animation: Loading_rotate__n7LtR 2s linear infinite;
}

@keyframes Loading_rotate__n7LtR {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACI,mDAAA;AACJ;;AAEA;EACI;IACI,uBAAA;EACN;EACE;IACI,yBAAA;EACN;AACF","sourcesContent":[".loading_icon{\n    animation: rotate 2s linear infinite;\n}\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading_icon": `Loading_loading_icon__GIgFG`,
	"rotate": `Loading_rotate__n7LtR`
};
export default ___CSS_LOADER_EXPORT___;
