// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookFlight_grid__wJco3 {
  margin: 20px 0px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  width: 100%;
  height: 100%;
}
.BookFlight_grid__wJco3 .BookFlight_seat__LxMa1 {
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/book_flight/BookFlight.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,sCAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AACJ;AAAI;EACI,uBAAA;EAAA,kBAAA;EACA,YAAA;AAER","sourcesContent":[".grid{\n    margin: 20px 0px;\n    display: grid;\n    grid-template-columns: repeat(10, 1fr);\n    grid-gap: 10px;\n    width: 100%;\n    height: 100%;\n    .seat {\n        width: fit-content;\n        height: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `BookFlight_grid__wJco3`,
	"seat": `BookFlight_seat__LxMa1`
};
export default ___CSS_LOADER_EXPORT___;
